import './App.scss';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import ConsolePage from './pages/ConsolePage';
import { lazy, Suspense } from 'react';

const ConsolePageLazy = lazy(() => import('./pages/ConsolePage'));

function App() {
  return (
    <div data-component="App">
      <Suspense fallback={<div>Loading...</div>}>
        <ConsolePageLazy />
      </Suspense>
    </div>
  );
}

export default App;
